/**
 * 智能工作流服务
 * 生产厂家 / 设备型号
 */
import  request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * post 请求获取任务列表
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getTaskListService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workTasks/list'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 编辑任务
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const editTaskListService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workTasks/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 获取工作流列表
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getWorkflowListService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workFlow/noPageList'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 获取工作流列表(简版)
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getWorkflowSimpleListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/inspection/workFlow/simple/list'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 指派人用户列表
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getAssignUserListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/inspection/workTasks/assignment/executor/user/list'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 删除任务列表
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delTaskListService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workTasks/del'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 删除任务分类
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delTaskTypeService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workTasks/classification/delete'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 升级任务分类
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const upgradeTaskTypeService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workTasks/classification/upgrade'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 更新任务状态
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateTaskStatusService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workTasks/publish'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求获取工作流程列表
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getProcessListService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workFlow/list'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 删除工作流程列表
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delProcessListService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workFlow/del'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 修改工作流名称
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateProcessListNameService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workFlow/editName'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 请求获取执行记录列表
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getRecordListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/inspection/workTasks/executionRecord/list?taskName='+params.taskName+'&flowName='+params.flowName+'&taskId='+params.taskId+'&status='+params.status+'&tasksType='+params.tasksType+'&size='+params.size+'&page='+params.page))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 删除执行记录列表
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const delRecordListService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workTasksRecord/del'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 查询执行记录详情
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getRecordInfoService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/inspection/workTasks/executionRecord/data/all?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 查询执行记录视频列表
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getRecordVideoListService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/inspection/workTasks/executionRecord/video/list?executionRecordId='+params.executionRecordId))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 上传工作流图片
 * @param params
 * @returns {Promise<*>}
 */
export const addWorkflowImage = evnUrl.concat('/inspection/resources/headPortrait');

/**
 * post 请求 编辑工作流
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const editWorkFlowService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workFlow/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 获取工作流信息
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getWorkFlowInfoService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workFlow/detail'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 获取工作流节点
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const getWorkFlowNodesService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/inspection/workFlow/allow/nodes'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 工作流更新发布状态
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const updateWorkFlowStatusService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workFlow/publish'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 拉取图片上传所需要的参数
 * @param params
 * @returns {Promise<*>}
 */
export const getImageFormDataService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/inspection/resources/headPortrait?type='+params.type))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 上传图片
 * @param params
 * @returns {Promise<*>}
 */
export const getImageUpLoadService = async (url,params) => {
    try {
        const { data } = await request.postUpLoad(url,params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 拉取资源图片
 * @param params
 * @returns {Promise<*>}
 */
export const getImageUrlService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/inspection/resources/url?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * get 请求 批量拉取资源图片
 * @param params
 * @returns {Promise<*>}
 */
export const getAllImageUrlService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/inspection/resources/all/url?idList='+params))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}


/**
 * post 请求 添加资料文件
 * @param formData
 * @param url
 * @param params
 * @returns {Promise<*>}
 */
export const addUploadDataService = async (params) => {
    try {
        const { data } = await request.postUpLoad(evnUrl.concat('/inspection/resources/upload'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 请求 下载记录数据
 * @param params
 * @returns {Promise<*>}
 */
export const getRecordDataService = async (params) => {
    try {
        const { data } = await request.postDownLoad(evnUrl.concat('/inspection/workTasksRecord/export/excel'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 请求 工作任务获取所有分类
 * @param params
 * @returns {Promise<*>}
 */
export const getTaskClassAllService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/inspection/workTasks/classification/list/all?tasksType='+params.tasksType))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 工作任务添加分类
 * @param params
 * @returns {Promise<*>}
 */
export const addTaskClassService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workTasks/classification/add'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
/**
 * post 请求 工作任务修改分类
 * @param params
 * @returns {Promise<*>}
 */
export const updateTaskClassService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/inspection/workTasks/classification/edit'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

